import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import rolodex from 'goodeggs-rolodex';
import {useSelector} from 'react-redux';

import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import {PageType} from 'web/helpers/redux_client';
import Alert from 'web/components/alert';
import SignInHelpers from 'web/components/market_layout/components/header/components/signin_helpers';
import {MarketLayoutStore} from 'web/components/market_layout/store_builder';
import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';
import LoadingOverlay from 'web/components/loading_overlay';

import {getCheckoutSessionUrl, Plans} from './helpers/api_client';

interface FAQ {
  title: string;
  description: string | ReactNode;
}

export const faqList: FAQ[] = [
  {
    title: 'What is the Good Eggs Membership?',
    description:
      'Good Eggs members enjoy unlimited deliveries with zero fees, priority customer service, and surprise and delight moments along the way.',
  },
  {
    title: 'How much does it cost to become a member?',
    description: (
      <>
        <p>Good Eggs offers two membership options for you to choose from:</p>
        <ul>
          <li>
            Monthly Membership: Enjoy zero fees, priority customer service, and surprise and delight
            moments for $29 per month – a savings of $50+ per month on average.
          </li>
          <li>
            Annual Membership: Save even more with our annual membership offering and enjoy all the
            benefits year-round for $299 per year.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'When will my benefits start?',
    description: 'Membership benefits start on the day you enroll.',
  },
  {
    title: 'When will I be charged?',
    description:
      'Your credit card will be charged at the time of enrollment. Your membership will automatically renew on the same day each month if you select the monthly option and the same day each year if you select the annual option.',
  },
  {
    title: 'Is there an order minimum?',
    description: 'The order minimum for all orders, regardless of membership status, is $50.',
  },
  {
    title: 'Can I pause my membership?',
    description: (
      <p>
        Members may pause membership for 1 month, 3 months, 6 months, or 12 months. Visit your{' '}
        <a href="/account/membership">Account page</a> and click on ‘Membership’, then ‘Cancel’,
        then ‘Pause’ to pause your membership.
      </p>
    ),
  },
  {
    title: 'Is there a free trial?',
    description:
      'At this time, we don’t offer a free trial, but you have the ability to cancel at any time.',
  },
  {
    title: 'How do I cancel my membership?',
    description: (
      <p>
        Visit your <a href="/account/membership">Account page</a> and click on ‘Membership’, then
        ‘Cancel’, then ‘Cancel’ again to cancel your membership. Your benefits will continue through
        the end of the current billing cycle.
      </p>
    ),
  },
  {
    title: 'Want to get in touch with us?',
    description: (
      <p>
        We want your feedback — reach out to{' '}
        <a href={`mailto:${rolodex.eaterHelpEmailAddress()}`}>{rolodex.eaterHelpEmailAddress()}</a>{' '}
        or text {rolodex.eaterHelpPhoneNumber()} and we’ll get back to you soon as we can.
      </p>
    ),
  },
];

const JoinMembershipPage: PageType<MarketLayoutStore> = () => {
  const {
    error: stateError,
    isMember,
    hasNeverBeenMember,
    isLoggedIn,
  } = useSelector((state: MarketLayoutStore) => ({
    error: state.error,
    isMember: state.user?.membership?.isMember,
    hasNeverBeenMember: state.user?.membership === undefined,
    isLoggedIn: state.user != null,
  }));
  const [error, setError] = useState<{message: string; heading: string} | null>(stateError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [returnTo, setReturnTo] = useState<string | undefined>(undefined);
  const [selectedPlan, setSelectedPlan] = useState<Plans>('yearly');
  const clientSettings = useClientSettings();
  const isNewMembershipFlowEnabled = clientSettings.membership.enableNewFlow;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Different behaviors for returnTo
    const returnToParam = queryParams.get('returnTo');

    if (returnToParam?.includes('stripe')) {
      const plan = returnToParam.split('-')[1];
      if (plan === 'monthly' || plan === 'yearly') {
        setIsLoading(true);
        setSelectedPlan(plan);
        handleSubmit();
        return;
      }
    }

    if (returnToParam === 'checkout') {
      setReturnTo('/basket/review?membership=active');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (): Promise<void> => {
    if (!isLoggedIn) {
      SignInHelpers.signinAndReturn(`returnTo=stripe-${selectedPlan}`);
      return;
    }

    if (isMember) {
      window.location.href = '/account/membership';
      return;
    }

    try {
      const stripeOptions = isNewMembershipFlowEnabled
        ? {
            plan: selectedPlan,
            includeTrial: hasNeverBeenMember && selectedPlan === 'yearly',
            returnTo,
          }
        : undefined;
      const stripeUrl = await getCheckoutSessionUrl(stripeOptions);
      window.location.href = stripeUrl;
    } catch (apiError) {
      if ((apiError as {type: string; message: string}).type) {
        setError({
          heading: 'Membership Checkout error',
          message: (apiError as {type: string; message: string}).message,
        });
      } else {
        setError({
          heading: 'Membership Checkout error',
          message:
            'Sorry, there was a problem connecting to the Membership Checkout. Try again later!',
        });
      }
    }
  };

  const flexiblePlansSectionRef = useRef<HTMLInputElement | null>(null);

  const handleScrollToFlexiblePlans = (): void => {
    flexiblePlansSectionRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
  };

  const handleReorderStables = (): void => {
    window.location.href = '/reorder';
  };

  const handleSelectPlan = (plan: Plans): void => {
    setSelectedPlan(plan);
  };

  const HelmetComponent = (
    <Helmet>
      <title>Join Membership | Good Eggs</title>
    </Helmet>
  );

  const NewMembershipLayout = (
    <div className="membership-container">
      {isLoading && <LoadingOverlay />}
      {HelmetComponent}

      <div data-testid="membership-hero" className="membership-hero">
        <div className="membership-hero__container">
          <div className="membership-hero__container__description">
            <p
              data-testid="primary-description"
              className="membership-hero__container__description__primary"
            >
              Save 70% on service and delivery fees.
            </p>
            <p
              data-testid="auxiliary-description"
              className="membership-hero__container__description__auxiliary"
            >
              Become a Good Eggs Member and save on every order.
              <br />
              <span className="membership-hero__container__description__auxiliary__bold">
                First month of annual membership is free
              </span>
              , *terms apply.
            </p>
          </div>
          <div className="membership-hero__container__start_trial_container">
            <button
              type="button"
              className="membership-hero__container__start_trial pill_button"
              data-testid="start-trial-button"
              onClick={handleScrollToFlexiblePlans}
            >
              START ONE MONTH TRIAL
            </button>
          </div>
        </div>
      </div>

      <div data-testid="membership-become-member" className="membership-become-member">
        <div className="membership-become-member-title__container">
          <p className="membership-become-member-title__description">Why become a member?</p>
        </div>
        <div className="membership-become-member-benefits">
          <div className="membership-become-member-benefits__container">
            <div className="membership-become-member-benefit__container">
              <div className="membership-become-member-benefit__icon-container">
                <img
                  data-testid="piggy-icon"
                  src={`${assetPath('/img/svg/icons/piggy.svg')}?auto=format`}
                />
              </div>
              <div className="membership-become-member-benefit__description-container">
                <span className="membership-become-member-benefit__primary">$0 Delivery Fees</span>
                <span className="membership-become-member-benefit__auxiliary">
                  On every order, delivery is free.
                  <br />
                  **Reduced serviced fees apply for members only.
                </span>
              </div>
            </div>
            <div className="membership-become-member-benefit__container">
              <div className="membership-become-member-benefit__icon-container">
                <img
                  data-testid="early-access-icon"
                  src={`${assetPath('/img/svg/icons/early-access-clock.svg')}?auto=format`}
                />
              </div>
              <div className="membership-become-member-benefit__description-container">
                <span className="membership-become-member-benefit__primary">Early Access</span>
                <span className="membership-become-member-benefit__auxiliary">
                  Get regular first dibs on select items from the best producers and farmers.
                </span>
              </div>
            </div>
          </div>

          <div className="membership-become-member-benefits__container">
            <div className="membership-become-member-benefit__container">
              <div className="membership-become-member-benefit__icon-container">
                <img
                  data-testid="gift-icon"
                  src={`${assetPath('/img/svg/icons/gift.svg')}?auto=format`}
                />
              </div>
              <div className="membership-become-member-benefit__description-container">
                <span className="membership-become-member-benefit__primary">
                  Extra Savings Four Times a Year
                </span>
                <span className="membership-become-member-benefit__auxiliary">
                  Once per quarter, we invite you to stock up and save. Get unmatched deals across
                  our aisles from local produce to best quality skincare and wellness products and
                  wine to pantry staples.
                </span>
              </div>
            </div>
            <div className="membership-become-member-benefit__container">
              <div className="membership-become-member-benefit__icon-container">
                <img
                  data-testid="customer-service-icon"
                  src={`${assetPath('/img/svg/icons/customer-service.svg')}?auto=format`}
                />
              </div>
              <div className="membership-become-member-benefit__description-container">
                <span className="membership-become-member-benefit__primary">
                  Priority Customer Service
                </span>
                <span className="membership-become-member-benefit__auxiliary">
                  Members are first-in-line for support with a dedicated customer-service email
                  address and phone number.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={flexiblePlansSectionRef}
        data-testid="membership-flexible-plans"
        className="membership-flexible-plans"
      >
        <div className="membership-flexible-plans-title__container">
          <p className="membership-flexible-plans-title__description">Flexible Billing Plans</p>
        </div>
        <div className="membership-flexible-plans-options__container">
          <div
            className={`membership-flexible-plans-options__option ${
              selectedPlan === 'monthly' ? 'plan_selected' : ''
            }`}
            onClick={() => handleSelectPlan('monthly')}
            data-testid="monthly-option"
          >
            <div className="membership-flexible-plans-options__title-container">
              <p className="membership-flexible-plans-options__title">Monthly Plan</p>
              <div className="membership-flexible-plans-options__price-container">
                <div className="membership-flexible-plans-options__price">
                  <p className="membership-flexible-plans-options__price-description">
                    $29.00/Month
                  </p>
                </div>
                <div className="membership-flexible-plans-options__savings">
                  <p className="membership-flexible-plans-options__savings-description">
                    ($348 per year)
                  </p>
                </div>
              </div>
            </div>
            <div className="membership-flexible-plans-options__separator" />
            <div className="membership-flexible-plans-options__billing-frequency-container">
              <p className="membership-flexible-plans-options__billing-frequency-description">
                Billed Monthly
              </p>
            </div>
          </div>
          <div
            className={`membership-flexible-plans-options__option ${
              selectedPlan === 'yearly' ? 'plan_selected' : ''
            }`}
            onClick={() => handleSelectPlan('yearly')}
            data-testid="yearly-option"
          >
            <div className="membership-flexible-plans-options__selected_option_frame">
              <p className="membership-flexible-plans-options__selected_option_frame_description">
                Save $49.00
              </p>
            </div>
            <div className="membership-flexible-plans-options__title-container">
              <p className="membership-flexible-plans-options__title">Yearly Plan</p>
              <div className="membership-flexible-plans-options__price-container">
                <div className="membership-flexible-plans-options__price">
                  <p className="membership-flexible-plans-options__price-description">
                    $299.00/Year
                  </p>
                </div>
                <div className="membership-flexible-plans-options__savings">
                  <p className="membership-flexible-plans-options__savings-description">
                    (Equivalent to $24.90 per month)
                  </p>
                </div>
              </div>
            </div>

            <div className="membership-flexible-plans-options__separator" />
            <div className="membership-flexible-plans-options__billing-frequency-container">
              <p className="membership-flexible-plans-options__billing-frequency-description">
                *First month free,
                <br />
                billed yearly
              </p>
            </div>
          </div>
        </div>
        <div className="membership-flexible-plans-start-saving__container">
          <button
            type="button"
            className="membership-flexible-plans-start-saving__button pill_button"
            data-testid="start-saving-button"
            onClick={async () => handleSubmit()}
          >
            START SAVING
          </button>
        </div>
        <div className="membership-flexible-plans-footer__container">
          <p className="membership-flexible-plans-footer__description">
            *Only for new members
            <br />
            **Service fees for members are a flat $5 versus a higher service fee price for
            non-members
          </p>
        </div>
      </div>

      <div data-testid="membership-split-story" className="membership-split-story">
        <div className="membership-split-story__image_container" />
        <div className="membership-split-story__content_container">
          <div className="membership-split-story__text_container">
            <div className="membership-split-story__primary_container">
              <p className="membership-split-story__primary">
                How to grocery shop in 10ish minutes
              </p>
            </div>
            <div className="membership-split-story__auxiliary_container">
              <p className="membership-split-story__auxiliary">
                {' '}
                Bookmark the “Reorder” page to speed through your shop! Visit the{' '}
                <span className="membership-split-story__auxiliary__bold">reorder page</span> on our
                site to quickly find items from past orders and a set of recommendations based on
                items you order frequently.
              </p>
            </div>
          </div>
          <div className="membership-split-story__reorder_button_container">
            <button
              type="button"
              className="membership-split-story__reorder_button outline_pill_button"
              data-testid="reorder-button"
              onClick={handleReorderStables}
            >
              REORDER YOUR STAPLES
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const OldMembershipLayout = (
    <div className="join-membership">
      {HelmetComponent}

      <main className="join-membership__content">
        <section className="join-membership__description">
          <h1 className="join-membership__title">
            <strong>Become a Good Eggs Member and save on every order</strong>
          </h1>
          <hr className="small-divider" />
        </section>
        <div className="join-membership__body">
          <section className="join-membership__benefits">
            <h1 className="join-membership__title">
              <strong>Why become a member?</strong>
            </h1>

            <p className="join-membership__benefit_description">
              For Good Eggs customers, an annual or monthly membership is a great way to save money
              on every order. In addition, membership entitles you to a list of great perks,
              including:
            </p>

            <h3 className="join-membership__benefit_header">early access</h3>
            <p className="join-membership__benefit_description">
              Get regular first dibs on select items from the best producers and farmers.
            </p>
            <h3 className="join-membership__benefit_header">reduced fees</h3>
            <ul className="join-membership__benefit_description join-membership__benefit_description__list">
              <li>
                Members enjoy close to 70% savings per order in service and delivery fees with just
                one flat $5.00 rate versus $15.99 for non-members.
              </li>
              <li>
                If you’re paying for an annual membership, you make up the cost of membership if you
                order more than twice per month.
              </li>
              <li>
                And if you’re a monthly member, you make up the cost of membership if you order more
                than three times per month.
              </li>
            </ul>
            <h3 className="join-membership__benefit_header">
              a chance to rack up savings four times a year
            </h3>
            <p className="join-membership__benefit_description">
              Once per quarter, we invite you to stock up and save. Get unmatched deals across our
              aisles from local produce to best quality skincare and wellness products and wine to
              pantry staples.
            </p>
            <h3 className="join-membership__benefit_header">priority customer service</h3>
            <p className="join-membership__benefit_description">
              Members receive a dedicated email address and phone number to reach our members-only
              customer service team at any time, and priority-level response time.
            </p>
          </section>
          <div className="membership__row_image">
            <img
              alt="hands carrying a GoodEggs box"
              src={`${assetPath('/img/web/join-membership/background.png')}?auto=format`}
            />
          </div>
        </div>
        <section className="join-membership__join">
          <div className="join-membership__join__content">
            <button
              type="submit"
              className="btn-join-membership"
              onClick={async () => handleSubmit()}
            >
              {!isMember ? 'JOIN MEMBERSHIP' : 'MANAGE MEMBERSHIP'}
            </button>
            {error && (
              <div className="join-membership__error" data-testid="join-membership__error">
                <Alert type="error" heading={error.heading}>
                  {error.message}
                </Alert>
              </div>
            )}
          </div>
        </section>

        <footer className="join-membership__footer">
          <a href="/terms/membership">Good Eggs Membership Terms & Conditions</a>
        </footer>
      </main>
    </div>
  );

  return (
    <MarketLayout disableBasketDropdown>
      {isNewMembershipFlowEnabled ? NewMembershipLayout : OldMembershipLayout}
    </MarketLayout>
  );
};

JoinMembershipPage.pageName = 'Join Membership';
JoinMembershipPage.reducer = (state, action) => {
  if (!state) throw new Error('State should always be preloaded here');

  const newState = marketLayoutReducer(state, action);
  return newState;
};

export default JoinMembershipPage;
